<template>
  <div>
    <div class="qrcode">
      <img v-if="qrcodeUrl === 'ZSY'" style="height: 100%;width: 100%;" src="../../assets/images/zsyqrcode.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qrcodeUrl: ''
    }
  },
  mounted() {
    console.log(this.$route.query.url);
    if (this.$route.query.url === 'ZSY') {
      this.qrcodeUrl = 'ZSY'
    } else if (this.$route.query.url === 'YTSS') {
      window.location.href = process.env.VUE_APP_ENV === 'production' ? 'http://xuexiao.fsytss.com' : 'http://testxuexiao.fsytss.com'
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  text-align: center;
}

.qrcode {
  height: 100vh;
  width: 100vw;
}
</style>